/*@media (min-width: 576px) {  }*/

/* !*Medium devices (tablets, 768px and up)*!*/
/*@media (min-width: 768px) {  }*/

/* !*Large devices (desktops, 992px and up)*!*/
/*@media (min-width: 992px) {  }*/

/* !*Extra large devices (large desktops, 1200px and up)*!*/
/*@media (min-width: 1200px) {  }*/

.container h2 {
    padding: 1rem;
}

.innerContent {
    padding: 0 1rem;
}

.timeLineCenter {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-bottom: 5rem;
}
