.subTitle {
  text-decoration: underline;
  font-weight: bold;
}

.underLine {
  text-decoration: underline;
}

.catTitle {
  text-decoration: underline;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .twoSplit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  }