.brewingGraph {
  display: none;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .brewingGraph {
    display: block;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  }