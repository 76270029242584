
.contentBlock {
  margin-top: 2rem;
}

img {
  pointer-events: none;
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .main {
    display: grid;
    grid-template-columns: 25% auto;
    white-space: pre-line;
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .main {
    display: grid;
    grid-template-columns: 15% auto;
    white-space: pre-line;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {  }
